<template>
  <div v-drag>
    <a-modal title="查询"
             v-model:visible="dialogVisible"
             @ok="search"
             width="520rem">
      <a-row class="input_Warp">
        <div style="width:80rem;font-size:18rem"> 关键字</div>
        <a-input placeholder="请输入"
                 allowClear
                 style="flex:1"
                 v-model:value="keywords" />
      </a-row>

      <a-row class="input_Warp"
             v-show="showTag">
        <div style="width:80rem;font-size:18rem"> 标签</div>
        <a-select style="flex:1"
                  v-model:value="question_favorite_type_id"
                  placeholder="请选择"
                  allowClear
                  :options='tagList.map(item=>({label:item.type_name,value:item.question_favorite_type_id}))'>

        </a-select>
      </a-row>

      <template #footer>
        <div class="btn_warp">
          <div @click="handleClose">取消</div>
          <div @click="search">确定</div>
        </div>
      </template>

    </a-modal>
  </div>
</template>

<script setup>

import { ref, toRefs, defineExpose } from "vue"
import { collectTagList } from "@/api/collect.js"


let dialogVisible = ref(false)
let keywords = ref('')
let question_favorite_type_id = ref('')
let tagList = ref([])


let { showTag } = defineProps(['showTag'])

const emit = defineEmits(['getData'])
const handleClose = () => {
  dialogVisible.value = false
}
const search = () => {
  emit('getData', keywords.value, question_favorite_type_id.value)
  dialogVisible.value = false
}

const setData = (bool, str, tagId) => {
  dialogVisible.value = bool
  keywords.value = str
  question_favorite_type_id.value = tagId || []
}
const getTagList = async () => {
  const { data } = await collectTagList()
  tagList.value = data.list
}
getTagList()

defineExpose({
  setData,
  dialogVisible,
  showTag,
  tagList,
  keywords,
  question_favorite_type_id
})

</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23px;
  font-size: 16px;
  padding: 27px;
  padding-top: 0;
}

.btn_warp {
  // border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // margin-bottom: -30rem;
  div {
    flex: 1;
    font-weight: bold;
    font-size: 20rem;
    // padding: 18rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(2) {
      color: #2196f3;
      border-left: 1px solid #e5e5e5;
    }
  }
}
:deep .ant-input-affix-wrapper > input.ant-input {
  font-size: 18rem;
}

.input_Warp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  span {
    flex: 1;
  }
}
</style>