import { instance } from '@/utils/http.js'

// 首页获取知识点-做卷
export const getHomeKnowledge = () => {
  return instance({
    url: '/api/v1/knowledge/home_paper_knowledge',
    method: 'get',
  })
}



// 首页获取知识点弱点
export const getHomeWeak = () => {
  return instance({
    url: '/api/v1/knowledge/home_weakness',
    method: 'get',
  })
}



// 知识点主页-根据科目获取知识点
export const getKnowledgeList = (params) => {
  return instance({
    url: '/api/v1/public/get_knowledge',
    method: 'get',
    params
  })
}


// 知识点弱点 列表 根据科目获取
export const getWeakList = (params) => {
  return instance({
    url: '/api/v1/knowledge/weakness',
    method: 'get',
    params
  })
}

// 能力素养 列表 根据科目获取
export const getCapList = (params) => {
  return instance({
    url: '/api/v1/public/get_capability',
    method: 'get',
    params
  })
}





//优质课程》视频讲解>视频列表，根据知识点id获取视频列表
export const getKnowledgeByParentid = function (params) {
  return instance({
    url: '/api/v1/video/knowledge_video_lst',
    method: 'get',
    params
  })
}
