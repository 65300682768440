<template>
  <div class="dropdown_wrap"
       :style="{'background':background}">
    <a-dropdown trigger="click"
                placement="bottom">
      <a class="ant-dropdown-link">
        <!-- 若list数组 是菜单则直接用索引，若为某一属性值则还需传属性名 -->
        <span v-if='attribute_name'>{{list[index]?list[index][attribute_name]: ''}} </span>
        <span v-else>{{list[index]?list[index]: ''}}</span>
        <img src="@/static/select.png"
             alt="">
      </a>

      <template #overlay>
        <a-menu id='menu'>
          <a-menu-item v-for="(item,index) in list"
                       @click.prevent="handleChange(index,item)"
                       :key="index"><span v-if='attribute_name'>{{item[attribute_name]}} </span>
            <span v-else>{{item}}</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup>
import { toRefs, watch, defineProps } from 'vue'

const props = defineProps({
  list: {
    type: Array,
    default: []
  },
  index: {
    type: Number,
    default: 0
  },
  attribute_name: {
    type: String,
    default: ''
  },
  background: {
    type: String,
    default: '#303fd4'
  },
})
let list = toRefs(props).list

let index = toRefs(props).index
let attribute_name = toRefs(props).attribute_name

const emits = defineEmits(['changeMenu'])
const handleChange = (index, item) => {
  emits('changeMenu', index, item,)
}
</script>

<style lang='scss' scoped>
.dropdown_wrap {
  // background-color: #1372e9;
  background: #303fd4;
  border-radius: 35rem;
  height: 60rem;
  min-width: 200rem;
  padding: 5rem 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 32rem;
  a {
    color: #fff;
    font-size: 32rem;
    padding-left: 20rem;
  }
  .ant-dropdown-link {
    font-size: 31rem;
    // width: 200rem;
    height: 60rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 26rem;
      height: 26rem;
      margin-left: 30rem;
    }
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-size: 24rem !important;
    line-height: 1.7em;
  }
}
:deep .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 24rem !important;
  line-height: 1.7em;
}
:deep .ant-dropdown {
  font-size: 24rem !important;
  line-height: 1.7em;
}
</style>