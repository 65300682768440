<template>
  <div v-drag
       class='mydialog'>
    <a-modal v-model:visible="dialogVisible"
             :maskClosable="false"
             width="1050rem"
             @cancel="handleClose"
             style='min-width:300px'
             :bodyStyle="{fontSize:'24rem',padding:'10rem 30rem',height:'630rem',overflow:'scroll' }"
             :footer="null">
      <template #title>
        <div class="top">
          <div class="top_title">视频列表
          </div>
          <div class="container">
            <input type="text"
                   class="search"
                   v-model="keywords"
                   placeholder="请输入关键字"
                   @keydown.enter="searchFn"
                   @change="inputFn">
            <div class="icon">
              <img src="@/static/icons/search.png"
                   @click="searchFn"
                   alt="">
            </div>
          </div>

        </div>

      </template>
      <div class="center_table">
        <div class="table_head">
          <div>
            知识点
          </div>
          <div>
            操作
          </div>
        </div>
      </div>
      <div class="table_line"
           v-for="(item,index) in list"
           :key="index">
        <div class="two">
          <div>
            {{item.filename}}
          </div>
          <div>
            <a-button type="primary"
                      @click="toDetail(item)">
              视频讲解
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>


<script setup>
import { getKnowledgeByParentid } from '@/api/knowledge.js'
import { InputSearch, message } from 'ant-design-vue';
import { useRouter } from 'vue-router'
import { ref, watch } from 'vue'
let router = useRouter()
let dialogVisible = ref(false)
let list = ref([])
let subjectId = ref('')
const value = ref('')
const keywords = ref('')
const handleClose = () => {
  keywords.value = ""
  dialogVisible.value = false
}
const toDetail = (item) => {
  router.push('/course/preview?type=video&url=' + item.url + "&knowledge_desc=" + item.knowledge_desc)
  dialogVisible.value = false
}
// const change = (index, bool) => {
//   this.$forceUpdate()
// }
let getKnowledgeList = async () => {
  let params = {
    knowledge_id: subjectId.value,
  }
  const { data } = await getKnowledgeByParentid(params)
  list.value = data.list
}
watch(keywords, (newValue, oldValue) => {
  if (keywords.value === '') {
    getKnowledgeList()
  }
});


// const inputFn = () => {
//   if (keywords.value === '') {
//     getKnowledgeList()
//   }
// }


const searchFn = async () => {


  let params = {
    keywords: keywords.value
  }

  if (keywords.value == "") {
    message.warning('请输入关键字');
    return
  } else {

    list.value = list.value.filter(item => item.filename.includes(keywords.value))
  }

  // console.log(data);
}
defineExpose({
  getKnowledgeList, dialogVisible,
  searchFn, subjectId
})


</script>

<style lang="scss" scoped>
.center_table {
  min-width: 300px;
}
.table_head {
  // height: 70rem;
  padding: 20rem 0;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24rem;

    color: #666660;
    &:nth-child(1) {
      flex: 3;
      text-indent: 60rem;
    }
    &:nth-child(2) {
      flex: 1;
      text-indent: 103rem;
    }
  }
}
.table_line {
  background: white;
  border-bottom: 1px solid #eaeaea;
  padding: 20rem 0;
  .two {
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
    div {
      &:nth-child(1) {
        flex: 3;
        text-indent: 60rem;
        padding-right: 70rem;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:nth-child(2) {
        flex: 1;
        text-indent: 33rem;
      }
    }
  }
}

.ant-btn {
  font-size: 24rem;
  padding: 7rem 20rem;
  border-radius: 5rem;
  height: auto;
}

// 搜索框
.container {
  position: relative;
  .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 30%; /* 距离右侧的距离 */
    top: 48%; /* 垂直居中 */
    transform: translateY(-50%); /* 沿垂直方向上偏移图标的一半高度 */
    z-index: 9999; /* 图标在上层 */
  }
  .icon img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top_title {
    flex: 1;
    font-size: 24rem;
    margin-left: 100rem;
  }

  .search {
    width: 200px;
    height: 35px;
    margin-right: 60rem;
    border-radius: 18px;
    outline: none;
    border: 1px solid #ccc;
    background-color: #1890ff;
    padding-left: 20px;
    padding-right: 50px;
    font-size: 14px;
    caret-color: #fff;
    color: #fff;
  }
  :deep .search::placeholder {
    color: #fff;
  }
}
</style>